<template>
  <div>
    <v-toolbar flat class="mb-4">
      <DateInput
        clearable
        dense
        hide-details
        v-model="filter.date"
        label="Stichtag"
      />
      <v-autocomplete
        hide-details
        clearable
        dense
        v-model="filter.type"
        :items="types"
        item-text="description"
        item-value="id"
        return-object
        class="mx-2"
        label="Typ"
      ></v-autocomplete>
      <PersonInput
        clearable
        dense
        hide-details
        label="für"
        v-model="filter.student"
        class="mr-2"
        group="student"
      />
      <PersonInput
        clearable
        dense
        hide-details
        label="von"
        class="mr-2"
        v-model="filter.creator"
        group="employee"
      />
      <v-spacer></v-spacer>
      <v-btn
        fab
        small
        depressed
        color="success"
        :to="{ name: 'PortfolioEdit', params: { id: 0 } }"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </v-toolbar>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="itemsFiltered"
        :items-per-page="15"
        :loading="loading"
        :item-class="() => 'clickable'"
        @click:row="(item) => showPortfolio(item.id)"
        sort-by="creation"
        sort-desc
      >
        <template v-slot:item.creation="{ item }">
          <DateValue :value="item.creationDate" />, {{ item.creationTime }}
        </template>
        <template v-slot:item.student="{ item }">
          <PersonItem :value="item.student" />
        </template>
        <template v-slot:item.course="{ item }">
          <Course :value="item.course" />
        </template>
        <template v-slot:item.creator="{ item }">
          <PersonItem :value="item.creator" />
        </template>
        <template v-slot:item.hidden="{ item }">
          <v-tooltip top v-if="item.hidden">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"><v-icon>mdi-eye-off</v-icon></span>
            </template>
            <span>Versteckt bis freigeschaltet wird</span>
          </v-tooltip>
        </template>
        <template v-slot:item.archived="{ item }">
          <v-tooltip top v-if="item.archived">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"><v-icon>mdi-archive</v-icon></span>
            </template>
            <span>Archiviert</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <router-view />
  </div>
</template>

<script>
import { comparePeople, searchPerson } from "common/utils/people.js";
import Course from "@/components/Course.vue";
import DateValue from "common/components/DateValue.vue";
import DateInput from "common/components/DateInput.vue";
import PersonItem from "@/components/PersonItem.vue";
import PersonInput from "common/components/PersonInput.vue";

export default {
  name: "PortfolioList",
  props: ["search"],
  components: { Course, DateValue, DateInput, PersonItem, PersonInput },
  data: () => ({
    filter: {
      date: null,
      student: null,
      creator: null,
      type: null,
    },
    headers: [
      { text: "erstellt", value: "creation" },
      { text: "Typ", value: "type.description", sortable: false },
      { text: "für", value: "student", sort: comparePeople },
      { text: "in", value: "course", sortable: false },
      { text: "von", value: "creator", sort: comparePeople },
      { text: "", value: "archived" },
      { text: "", value: "hidden" },
    ],
    items: [],
    types: [],
    loading: false,
  }),
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (item) =>
          (!this.filter.date || this.filter.date <= item.creationDate) &&
          (!this.filter.type || this.filter.type.id === item.type.id) &&
          (!this.filter.student ||
            this.filter.student.id === item.student.id) &&
          (!this.filter.creator ||
            this.filter.creator.id === item.creator.id) &&
          (!this.search ||
            item.type.description
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            searchPerson(item.creator, this.search) ||
            searchPerson(item.student, this.search))
      );
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      const portfolios = await this.apiList({
        resource: "portfolio/portfolio",
        query: "active",
      });
      for (const portfolio of portfolios) {
        portfolio.creation = portfolio.creationDate + portfolio.creationTime;
      }
      this.items = portfolios;
      this.types = await this.apiList({
        resource: "portfolio/type",
        query: "archived",
      });
      this.loading = false;
    },
    showPortfolio(id) {
      this.$router.push({
        name: "PortfolioDetail",
        params: { id: id },
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "PortfolioList") {
      this.getData();
    }
    next();
  },
  created() {
    this.getData();
  },
};
</script>
